import { useLocation } from '@msaf/router-react'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorCode } from '../constants'
import { usePermissionLoading } from '../services/permissions'
import { UserPermissions } from '../types/permissions'
import { RouteMode, RouteParams } from '../types/route'
import ErrorPage from './error-page'
import NotFound from './not-found'

interface ContextProps {
  errorCode?: ErrorCode
  setError: (code: ErrorCode) => void
}

export const DisplayContext = createContext<ContextProps>({
  setError: () => {
    /** no-op */
  },
})

export const useRedirect = (target: { mode: RouteMode; path?: undefined } | { mode?: RouteMode; path: string }) => {
  const location = useLocation()
  const { mode } = useParams<RouteParams>()
  const { setError } = useContext(DisplayContext)
  const navigate = useNavigate()

  return () => {
    if (target.path) {
      navigate(target.path, { replace: true })
    } else {
      if (mode == null) {
        setError(ErrorCode.SERVER_ERROR_500)
        throw new Error('Cannot redirect from an invalid mode')
      }
      navigate(location.pathname.replace(`/${mode}/`, `/${target.mode}/`), { replace: true })
    }
  }
}

export function useViewRedirectGatekeeper(
  editPermissions: UserPermissions | UserPermissions[],
  viewPermissions: UserPermissions | UserPermissions[],
  redirect?: string,
) {
  const { mode } = useParams<RouteParams>()
  const { setError } = useContext(DisplayContext)
  const navigate = useRedirect({ path: redirect, mode: RouteMode.VIEW })

  const { hasPermission: hasEditPermission, isLoading: isLoadingEditPermission } = usePermissionLoading(editPermissions)
  const { hasPermission: hasViewPermission, isLoading: isLoadingViewPermission } = usePermissionLoading(viewPermissions)

  useEffect(() => {
    if (mode === RouteMode.EDIT && !hasEditPermission && !isLoadingEditPermission) {
      if (hasViewPermission && !isLoadingViewPermission) {
        // If redirect path is not provided, default to replacing `edit` in the current route with `view`
        navigate()
      } else {
        setError(ErrorCode.PERMISSION_DENIED_403)
      }
    } else {
      if (!hasViewPermission && !isLoadingViewPermission) {
        setError(ErrorCode.PERMISSION_DENIED_403)
      }
    }
  }, [mode, hasEditPermission, isLoadingEditPermission, hasViewPermission, isLoadingViewPermission, navigate, setError])
  return { hasEditPermission, hasViewPermission, isLoading: isLoadingEditPermission || isLoadingViewPermission }
}

export function useGatekeeper(permissions: UserPermissions | UserPermissions[]) {
  const { setError } = useContext(DisplayContext)
  const { isLoading, hasPermission } = usePermissionLoading(permissions)

  useEffect(() => {
    if (!isLoading && !hasPermission) {
      setError(ErrorCode.PERMISSION_DENIED_403)
    }
  }, [isLoading, hasPermission, setError])
}

export const Gatekeeper = ({ children, inPage = false }: { children: ReactNode; inPage?: boolean }) => {
  const handler = useErrorHandler()

  const [errorCode, setError] = useState<ErrorCode | undefined>(undefined)
  const location = useLocation()

  useEffect(() => {
    // Reset error state on path change
    setError(undefined)
  }, [location.pathname])

  if (!errorCode) {
    return (
      <DisplayContext.Provider value={{ errorCode, setError: (error: ErrorCode) => setError(error) }}>
        {children}
      </DisplayContext.Provider>
    )
  }

  if (errorCode === ErrorCode.PERMISSION_DENIED_403) return <NotFound inPage={inPage} />

  if (errorCode === ErrorCode.NOT_FOUND_404) return <NotFound inPage={inPage} />

  if (errorCode === ErrorCode.SERVER_ERROR_500) {
    return (
      <ErrorPage
        inPage={inPage}
        headingText='Something went wrong'
        supportingText='An error occurred. Please contact your administrator if this continues.'
      />
    )
  }

  // Propagate upwards if the error cannot be handled here
  handler(errorCode)

  return null
}
