import { Link } from '@msaf/core-react'
import { useMemo } from 'react'
import { Path, PathValue, UnpackNestedValue } from 'react-hook-form'
import { UploadedFile } from '../../../../../../forms/components/file-controller'
import { FieldWatchResultProps } from '../../../../../../forms/components/hooks/use-field-watch'
import { FormElementConfig, LabelledFieldConfig } from '../../../../../../forms/types'
import { LookupItem, RepeatingLookupItem } from '../../../../../../types'
import { AppNoteType, NoteForm } from '../../../../../../types/notes'

export interface SystemsAspirationsFields {
  soilTypes: RepeatingLookupItem
  soilComment: string
  isLandErodible: boolean | null
  approxPercentErodiblePasture: Partial<LookupItem>
  erodibleLandComment: string | null
  streamBankErosionExists: boolean | null
  streamBankErosionLevel: Partial<LookupItem>
  streamBankErosionComment: string | null
  significantKnownWetlandsExist: boolean | null
  significantKnownWetlandsComment: string | null
  isCoastalMarineArea: boolean | null
  coastalMarineAreaComment: string | null
  isSignificantNaturalArea: boolean | null
  significantNaturalAreaComment: string | null
  culturalSignificance: string | null
  managementPractices: string | null
  farmForestrySystemsDescription: string
  landUseHistoryDescription: string
  environmentalStateDescription: string
  sustainabilityJourneyDescription: string
  hadPrevFundsForRemProjects: boolean
  prevFundsDescription: string | null
  hasExistingCouncilProjects: boolean
  files?: UploadedFile[]
  notes: Array<AppNoteType>
}

export const useSystemAspirationsForm = (
  data: SystemsAspirationsFields | undefined,
  isMigrated: boolean | undefined,
) => {
  return useMemo(
    () => [
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Biophysical factors' },
      },
      {
        type: 'field',
        element: {
          fieldId: 'soilTypes',
          type: 'lookup',
          lookupId: 'soilType',
          repeating: true,
          isRequired: !isMigrated,
          defaultValue: {},
          helpText: (
            <>
              {
                <Link
                  path='https://www.nrc.govt.nz/soilfactsheets'
                  label='Hyperlink to soil type fact sheets'
                  target='_blank'
                  isExternal
                  className='helptext-hyperlink'
                />
              }
              <p className='c-form-field__help-text'>
                The soil type layer can be viewed in the Map tab. Review the soil fact sheets before going on site.
                Share the soil fact sheets with landowners, if helpful.
              </p>
            </>
          ),
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'soilComment',
          type: 'text-area',
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'isLandErodible',
          type: 'boolean',
          isRequired: !isMigrated,
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          formPrefix: 'systemAspirations',
          fieldId: 'areaHa',
          conversion: (
            value: UnpackNestedValue<PathValue<SystemsAspirationsFields, Path<SystemsAspirationsFields>>>,
          ) => {
            return typeof value === 'number' ? `${value}ha` : '-'
          },
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'approxPercentErodiblePasture',
          type: 'lookup',
          lookupId: 'approxPercentErodiblePasture',
          isDisabled: !data?.isLandErodible,
          helpText: 'This is a very high level estimate to inform further conversations with the landowner. ',
          defaultValue: {},
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<SystemsAspirationsFields>) => {
            if (info.type === 'change' && info.name === 'isLandErodible') {
              if (!data?.isLandErodible) {
                methods.setValue('approxPercentErodiblePasture', { value: undefined })
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  isDisabled: true,
                }
              } else {
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  isDisabled: false,
                }
              }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'erodibleLandComment',
          type: data?.isLandErodible ? 'text-area' : 'read-only',
          helpText:
            'Refer to soil type, LUC, slope and indicative sediment risk layers, which are all available in the map tab, and on-site observations.',
          defaultValue: {},
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<SystemsAspirationsFields>) => {
            if (info.type === 'change' && info.name === 'isLandErodible') {
              if (!data?.isLandErodible) {
                methods.setValue('erodibleLandComment', null)
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  type: 'read-only',
                }
              } else {
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  type: 'text-area',
                }
              }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'streamBankErosionExists',
          type: 'boolean',
          isRequired: !isMigrated,
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'streamBankErosionLevel',
          type: 'lookup',
          lookupId: 'streamBankErosionLevel',
          isDisabled: !data?.streamBankErosionExists,
          defaultValue: {},
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<SystemsAspirationsFields>) => {
            if (info.type === 'change' && info.name === 'streamBankErosionExists') {
              if (!data?.streamBankErosionExists) {
                methods.setValue('streamBankErosionLevel', { value: undefined })
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  isDisabled: true,
                }
              } else {
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  isDisabled: false,
                }
              }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'streamBankErosionComment',
          type: data?.streamBankErosionExists ? 'text-area' : 'read-only',
          defaultValue: {},
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<SystemsAspirationsFields>) => {
            if (info.type === 'change' && info.name === 'streamBankErosionExists') {
              if (!data?.streamBankErosionExists) {
                methods.setValue('streamBankErosionComment', null)
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  type: 'read-only',
                }
              } else {
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  type: 'text-area',
                }
              }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Areas of significance' },
      },
      {
        type: 'field',
        element: {
          fieldId: 'significantKnownWetlandsExist',
          type: 'boolean',
          isRequired: !isMigrated,
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'significantKnownWetlandsComment',
          type: data?.significantKnownWetlandsExist ? 'text-area' : 'read-only',
          defaultValue: {},
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<SystemsAspirationsFields>) => {
            if (info.type === 'change' && info.name === 'significantKnownWetlandsExist') {
              if (!data?.significantKnownWetlandsExist) {
                methods.setValue('significantKnownWetlandsComment', null)
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  type: 'read-only',
                }
              } else {
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  type: 'text-area',
                }
              }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'isCoastalMarineArea',
          type: 'boolean',
          isRequired: !isMigrated,
          helpText: 'Fencing and planting actions require a minimum 10m setback from the coast (please refer to FAQ).',
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'coastalMarineAreaComment',
          type: data?.isCoastalMarineArea ? 'text-area' : 'read-only',
          defaultValue: {},
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<SystemsAspirationsFields>) => {
            if (info.type === 'change' && info.name === 'isCoastalMarineArea') {
              if (!data?.isCoastalMarineArea) {
                methods.setValue('coastalMarineAreaComment', null)
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  type: 'read-only',
                }
              } else {
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  type: 'text-area',
                }
              }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'isSignificantNaturalArea',
          type: 'boolean',
          isRequired: !isMigrated,
          helpText:
            'These may be identified in the field, or via the Vegetation and Significant Natural Areas layer on the Map tab',
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'significantNaturalAreaComment',
          type: data?.isSignificantNaturalArea ? 'text-area' : 'read-only',
          defaultValue: {},
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<SystemsAspirationsFields>) => {
            if (info.type === 'change' && info.name === 'isSignificantNaturalArea') {
              if (!data?.isSignificantNaturalArea) {
                methods.setValue('significantNaturalAreaComment', null)
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  type: 'read-only',
                }
              } else {
                return {
                  ...(initialConfig as LabelledFieldConfig<SystemsAspirationsFields>),
                  type: 'text-area',
                }
              }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          type: 'text-area',
          fieldId: 'culturalSignificance',
          isRequired: !isMigrated,
          helpText: (
            <>
              <p className='c-form-field__help-text'>
                The sites may not be directly on the property e.g. sites downstream may be impacted by land use. Please
                describe any important values associated with the sites of significance.
              </p>
              <p className='c-form-field__help-text'>
                The landowner be aware of site and/or sites may be identified at this website:{' '}
                {
                  <Link
                    path='https://archsite.eaglegis.co.nz/NZAAPublic'
                    label='Archeology site recordings scheme'
                    target='_blank'
                    isExternal
                  />
                }
              </p>
            </>
          ),
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Management practices' },
      },
      {
        type: 'field',
        element: {
          type: 'text-area',
          fieldId: 'managementPractices',
          isRequired: !isMigrated,
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Evidence of good practice' },
      },
      { type: 'field', element: { type: 'files', fieldId: 'files' } },
      {
        type: 'field',
        element: {
          type: 'notes',
          fieldId: 'notes',
          form: NoteForm.SYSTEMS_AND_ASPIRATIONS,
        },
      },
    ],
    [data, isMigrated],
  ) as FormElementConfig<SystemsAspirationsFields>[]
}
