import { usePageState } from '@msaf/core-react'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { RouteParams } from '../types/route'
import { useRequest } from './useRequest'
import { usePlanWarning } from './use-plan-warnings'
import { PlanStatusNames } from '../constants'
export interface PlanSummaryData {
  statusLabel: string
  statusName: PlanStatusNames
  statusDays: string
  heading: string
  location: [number, number] | null
  isMigrated: boolean
  hasEoi: boolean
  hasLongTermMonitoring: boolean
  reopenedApplications: string[]
}

export const usePlanSummary = () => {
  const { id } = useParams<RouteParams>()
  const { setPageState } = usePageState()
  const { client } = useRequest()

  const { data, refetch, isLoading } = useQuery<PlanSummaryData>(
    [id, 'summary'],
    () => client.get(`/api/plan/${id}/summary`).then((res) => res.data),
    {
      // Keep previous data while we fetch new data
      keepPreviousData: true,
    },
  )

  const { warnings } = usePlanWarning()

  useEffect(() => {
    if (data) {
      const tags = [] as string[]
      if (data.statusLabel) {
        if (data.statusDays) {
          tags.push(`${data.statusLabel} (${data.statusDays} days)`)
        } else {
          tags.push(data.statusLabel)
        }
      }

      const levels = warnings?.map((w) => w.level)
      if (levels?.includes('ERROR')) {
        tags.push('Limits exceeded')
      } else if (levels?.includes('WARNING')) {
        tags.push('Approaching limits')
      }

      if (data.reopenedApplications) tags.push(...data.reopenedApplications.map((a) => `Reopened Project ${a}`))
      if (data.hasLongTermMonitoring) tags.push('Long term monitoring')

      setPageState((prevState) => ({
        ...prevState,
        heading: data.heading,
        tags,
      }))
    }
    return () => setPageState((prevState) => ({ ...prevState, heading: undefined, tags: undefined }))
  }, [setPageState, data])

  return {
    statusName: data?.statusName,
    location: data?.location,
    isMigratedPlan: !!data?.isMigrated,
    hasEoi: !!data?.hasEoi,
    hasLongTermMonitoring: !!data?.hasLongTermMonitoring,
    refetch,
    isLoading,
  }
}
